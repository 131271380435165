import React from 'react';

import Layout from '../components/Layout';

const AboutPage = () => (
  <Layout fullMenu>
    <article id="main">
      <header className="feature"></header>
      <section className="wrapper style5">
        <div className="inner">
          <h3>About Me</h3>
          <p>
            Violinist Melanie Riordan is a unique performer and passionate
            educator currently based in Long Island, NY. For the last decade she
            has devoted her time and energy into spreading the joy of music and
            art through her versatile performances and strong educational
            efforts. A native of New Rochelle, NY, Melanie began taking violin
            lessons at the age of 10. Since then she has had the privilege of
            performing in some of the world's most prestigious concert halls and
            teaching hundreds of students across the United States, Canada,
            Europe, and Asia.
          </p>
          <p>
            An avid advocate of contemporary classical music, she has premiered
            dozens of works by both emerging and established composers of the
            20th and 21st centuries. Composers she has worked with include
            Christopher Theofanidis, David Lang, Annie Gosfield, Matthias
            Pintscher, and Peter Eötvös among others. She has held performance
            residencies at Mass MOCA, the Centre d’Arts Orford, and the Rothko
            Chapel as a Bang on a Can fellow, an Orford fellow and a DACAMERA
            Young Artist respectively. She has had the privilege of performing
            with members of 8th Blackbird, the Bang on a Can All-stars, the
            Philip Glass Ensemble, and the Silk Road Ensemble as well as
            visionaries Rhiannon Giddens and Saul Williams. Some especially
            inspiring projects include performing in the American premier of
            Kaija Saariaho’s “Émilie” the Spoleto Festival USA and world premier
            of Marty Regans chamber opera “The Memory Stone” with the Houston
            Grand Opera.
          </p>
          <p>
            Recent seasons included performances of Alban Berg’s haunting violin
            concerto with the Lynn Conservatory Philharmonia as the winner of
            its annual concerto competition, her New York City debut at the
            Kosciusko Foundation as a winner of the Lyric Chamber Music
            Society’s annual chamber competition and a European tour with the
            Lucerne Festival Alumni Orchestra under the baton of Ricardo
            Chailly. A versatile musician, Ms. Riordan has enjoyed playing in
            the traditional baroque style with the Houston Baroque ensembles Ars
            Lyrica and Mercury. Equally comfortable on pop/rock stages, Melanie
            particularly enjoyed performances with artists Andrea Bocelli,
            Michael Bublé, Michael Bolton, and Ledisi, and has performed folk
            music at the legendary Austin City Limits Music Festival.
          </p>
          <p>
            A devoted and passionate educator, Melanie was named Adjunct
            Professor of Violin at Florida Atlantic University in Boca Raton,
            Florida in 2020. She is also the Founder and Artistic Director of
            the Summer Strings Academy for Girls; an online summer festival for
            female-identifying string students from around the world. Melanie is
            passionate about using music as a way to uplift children. She was
            one of the first educators to launch the Houston Youth Symphony Coda
            Music program and has been a guest instructor for Sistemang Pilipino
            in Cebu City, Philippines, the Miami Music Project and the Nat King
            Cole “Generation Hope” music camp in partnership with Lynn
            University and the Plumosa School for the Arts. All of these
            programs are inspired by the famed Venezuelan music education
            program “El Sistema,” providing free music education and instruments
            to underprivileged and at-risk children. She received her
            pedagogical training from Mimi Zweig, Brenda Brenner, Rebecca Henry
            and Elizabeth Zemple at Indiana University and her Suzuki Violin
            Method Certification under the guidance of Charles Krigbaum and
            Edmund Sprunger. She is also highly influenced by her own violin
            teachers, Carole Cole, Kirsten Yon, Jonathan Crow. Melanie is
            currently pursuing her Doctoral degree at Stony Brook University.
          </p>
        </div>
      </section>
    </article>
  </Layout>
);

export default AboutPage;
